<template>
  <!-- begin::post-card-list -->
  <ul class="post-card-list-issue">
    <li class="post-card-item-issue"  v-for="(item, idx) in items" :key="idx">
      <div class="post-layer-card-issue">

        <router-link :to="`issue/${item.issueId}/view`" class="card-link"><span class="screen-out">자세히 보기</span></router-link>
        <div class="card-inner">
<!--          <div class="issue">-->
<!--            <small class="issue-title">issue</small>-->
<!--            <p class="issue-num">{{ item.issueNo }}</p>-->
<!--          </div>-->
          <div class="card-content">
<!--            <p class="subtitle">{{ item.issueSmlTit }}</p>-->
<!--            <h4 class="title">{{ item.issueTit }}</h4>-->
            <!--<p class="view">
              <i class="icon-view">main/view_icon.svg</i>
              <span class="text">{{ item.inqCnt }}</span>-->
          </div>
        </div>
        <div class="card-image">
          <img :src="item.rprsImgPtUrl" alt="" style="width:100%">
        </div>
      </div>
    </li>
  </ul>
  <!-- end::post-card-list -->
</template>

<script>
export default {
  name: 'IssueCard',
  props: {
    items: Array,
    viewLink: String
  }
};
</script>
